@import "../../global.scss";

.works{
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    // .arrow{
    //     height: 50px;
    //     position: absolute;

    //     &.left{
    //         left: 100px;
    //         transform: rotateY(180deg);
    //         cursor: pointer;
    //     }

    //     &.right{
    //         right: 100px;
    //         cursor: pointer;

    //     }
    // }

    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                // @include mobile{
                //     width: 90%;
                //     height: 200px;
                //     margin: 20px 0;
                // }

                .left{
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .imgContainer{
                            width: 80px;
                            height: 80px;
                            border-radius: 80%;
                            background-color: gray ;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img{
                                width: 70px;
                            }

                        }
                        h2{
                           font-size: 20px; 
                        }
                        p{
                            font-size: 13px;
                        }
                        // span{
                        //     font-size: 12px;
                        //     font-weight: 600;
                        //     text-decoration: underline;
                        //     cursor: pointer;
                        // }
                    }
                }
                .right{
                    flex: 6;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width: 400px;
                        transform: rotate(-10deg);
                    }

                }
            }
        }

    }

}